import { Chip, Grid } from '@mui/material'
import { Stack } from '@mui/system';
import React from 'react'

export default function TimeRangeSelector(props) {
    const {
        value,
        onChange
    } = props;

    const handleClickShort = () => {
        onChange(0);
    };

    const handleClickMedium = () => {
        onChange(1);
    };

    const handleClickLong = () => {
        onChange(2);
    };

    return (
        // <>
        // <Grid container align='center'>
        //     <Grid item xs={4}>
        //         <Chip 
        //             label="short term"
        //             color={value === 0 ? 'primary' : 'secondary'}
        //             variant={value === 0 ? 'filled' : 'outlined'}
        //             onClick={handleClickShort}
        //         />
        //     </Grid>
        //     <Grid item xs={4}>
        //         <Chip 
        //             label="medium term"
        //             color={value === 1 ? 'primary' : 'secondary'}
        //             variant={value === 1 ? 'filled' : 'outlined'}
        //             onClick={handleClickMedium}
        //         />
        //     </Grid>
        //     <Grid item xs={4}>
        //         <Chip 
        //             label="long term"
        //             color={value === 2 ? 'primary' : 'secondary'}
        //             variant={value === 2 ? 'filled' : 'outlined'}
        //             onClick={handleClickLong}
        //         />
        //     </Grid>
        // </Grid>
        <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
            <Chip 
                label="short term"
                color={value === 0 ? 'primary' : 'secondary'}
                variant={value === 0 ? 'filled' : 'outlined'}
                onClick={handleClickShort}
            />
            <Chip 
                label="medium term"
                color={value === 1 ? 'primary' : 'secondary'}
                variant={value === 1 ? 'filled' : 'outlined'}
                onClick={handleClickMedium}
            />
            <Chip 
                    label="long term"
                    color={value === 2 ? 'primary' : 'secondary'}
                    variant={value === 2 ? 'filled' : 'outlined'}
                    onClick={handleClickLong}
            />
        </Stack>
        // </>
    )
}
