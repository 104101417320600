import { Box, Divider, List, ListItem, Drawer, TextField, Typography, Grid, Button, Checkbox, ListItemButton, ListItemAvatar, Avatar, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react';

const descriptionHelperText = 'My recent top tracks on spotify';

export default function CreatePlaylistDrawer(props) {
    const {
        open = false,
        tracks,
        onClose,
        onSubmitPlaylist
    } = props;

    const [title, setTitle] = useState('My Top Tracks');
    const [description, setDescription] = useState(descriptionHelperText);
    const [headerHeight, setHeaderHeight] = useState(232);
    const [selectedTracks, setSelectedTracks] = useState([]);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    const handleSubmitMakePlaylist = () => {
        const finalTracks = selectedTracks.filter((track) => {
            return track.selected
        })

        onSubmitPlaylist(title, description, finalTracks)
    }

    const handleToggleIncludeTrack = (selectedTrack) => () => {
        const newSelectedTracks = selectedTracks.map((track, key) => {
            if (key === selectedTrack) {
                return {...track, selected: !track.selected}
            }
            return {...track}
        })

        setSelectedTracks(newSelectedTracks);
    }

    useEffect(() => {
        const newSelectedTracks = tracks.map((track) => {
            return {...track, selected: true}
        });

        setSelectedTracks(newSelectedTracks);
    }, [tracks]);

    useEffect(() => {
        if(description === descriptionHelperText) {
            return;
        }

        const drawerHeaderHeight = document.getElementById('drawerHeader').offsetHeight;
        const drawerHeaderListHeight = document.getElementById('drawerHeaderList').offsetHeight;

        if(drawerHeaderHeight !== drawerHeaderListHeight + 1) {
            setHeaderHeight(drawerHeaderListHeight + 1);
        }
        
    }, [description])

    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={onClose}
            id='drawer'
        >
            <Grid container 
                id='drawerHeader'
                sx={{
                    width: 250,
                    minHeight: headerHeight,
                    flex: '0 1 auto'
            }}>
            <Grid item xs={12} id='drawerHeaderContent'>
                    <List id='drawerHeaderList'>
                        <ListItem>
                            <Typography variant='h6'>
                                Create Playlist
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <TextField 
                                label='Title' 
                                value={title} 
                                variant='filled'
                                onChange={handleTitleChange}
                            />
                        </ListItem>
                        <ListItem>
                            <TextField 
                                label='Description' 
                                value={description}
                                variant='filled'
                                multiline
                                onChange={handleDescriptionChange}
                            />
                        </ListItem>
                    </List>
                    <Divider />
                </Grid>
            </Grid>
            <Grid container sx={{
                width: 250,
                flex: '1 1 auto',
                overflow: 'auto'
            }}>
                <Grid item xs={12} >
                    <List disablePadding>
                        {
                            selectedTracks.map((track, key) => {
                                return <React.Fragment key={key}>
                                    <ListItem 
                                        key={key}
                                        secondaryAction={
                                            <Checkbox
                                                edge="end"
                                                onChange={handleToggleIncludeTrack(key)}
                                                checked={track.selected}
                                            />
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton 
                                            alignItems='flex-start'
                                        >
                                            <ListItemAvatar>
                                                <Avatar
                                                    src={track.icon}
                                                    variant='square'
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={track.title} secondary={track.subtitle} />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            })
                        }
                    </List>
                </Grid>
            </Grid>
            <Grid 
                container 
                sx={{
                    width: 250,
                    minHeight: 66,
                    flex: '0 1 auto'
                }}
                align='center'
            >
                <Grid item xs={12} >
                    <Divider />
                    <List>
                        <ListItem sx={{justifyContent: 'center'}}>
                            <Button 
                                variant='contained' 
                                color='primary'
                                onClick={handleSubmitMakePlaylist}
                            >
                                    Make playlist
                            </Button>
                        </ListItem>
                    </List> 
                </Grid> 
            </Grid>
        </Drawer>
    )
}