import React from 'react';
import { Grid } from '@mui/material';
import MediaCard from './components/MediaCard';

export default function TopTracksMediaContainer(props) {
    const {
        topTracks
    } = props;

    return (
        <Grid container spacing={2}>
            {topTracks.map((track, key) => {
                return (
                    <MediaCard 
                        key={`top-track-${key}`}
                        title={track.title}
                        subtitle={track.subtitle}
                        image={track.image}
                        rank={key + 1}
                    />
                );
            })}
        </Grid>
    )
}
