import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'

export default function MediaCard(props) {
    const {
        title,
        subtitle,
        image,
        rank
    } = props;


    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card>
                <CardMedia
                    component="img"
                    height="240"
                    image={image}
                />
                <CardContent sx={{
                    paddingBottom: 2,
                    '&:last-child': {
                        paddingBottom: 2
                    }
                }}>
                    <Grid container spacing={2} sx={{flexWrap: 'inherit'}}>
                        <Grid item sx={{ display: 'flex', alignItems: 'end'}}>
                            <Typography variant='h3'>{rank}</Typography>
                        </Grid>
                        <Grid item sx={{flexGrow: 1, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'}}>
                            <Typography noWrap>{title}</Typography>
                            {subtitle && <Typography variant='caption' noWrap>{subtitle}</Typography>}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}
