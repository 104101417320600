import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import { Container } from '@mui/system';
import { Image } from 'mui-image';

const AUTH_URL = "https://accounts.spotify.com/authorize/?client_id=f999f88ebf894ec2b014e9ea144e4c48&response_type=code&redirect_uri=http://mylisteninghabits.com/&scope=user-read-email%20user-read-private%20user-top-read%20playlist-modify-private%20playlist-modify-public";

export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    const from = location.state?.from?.pathname || "/";

    if (auth.accessToken) {
        return <Navigate to="/" state={{ from: from }} replace />;
    }

    return (
        <Container align="center" sx={{paddingTop: 4, paddingBottom: 4}}>
            <Grid container spacing={4} sx={{alignItems: 'center'}}>
                <Grid item xs={12}>
                    <Typography sx={{typography: {xs: 'h4', sm: 'h2', md: 'h1'} }}>
                        My Listening Habits
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Typography sx={{ 
                        typography: {xs: 'h5', sm: 'h3'},
                        textAlign: {xs: 'center', sm: 'left'}
                    }}>
                        See your top artists 
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Image src='/static/topTracks.png' />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Image src='/static/makePlaylist.png' />
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                <Typography sx={{ 
                        typography: {xs: 'h5', sm: 'h3'},
                        textAlign: {xs: 'center', sm: 'right'}
                    }}>
                        Make new custom playlists
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1'>
                        Log in with Spotify to get started
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                    variant='contained'
                    href={AUTH_URL}
                    >
                        Login with Spotify
                    </Button>
                </Grid>
            </Grid>
            
            
        </Container>
    )
}