import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import SpotifyWebApi from "spotify-web-api-node";
import ConfigurationPanel from './components/ConfigurationPanel';
import useAuth from './useAuth';
import TopTracksMediaContainer from './TopTracksMediaContainer';
import CreatePlaylistDrawer from './components/CreatePlaylistDrawer';

const spotifyApi = new SpotifyWebApi({
    clientId: "f999f88ebf894ec2b014e9ea144e4c48",
});

export default function TopTracks() {
    const auth = useAuth();

    const [topArtists, setTopArtists] = useState([]);
    const [timeRange, setTimeRange] = useState(1);
    const [numberOfEntries, setNumberOfEntries] = useState(50);
    const [topTracksForArtists, setTopTracksForArtists] = useState([]);
    const [createPlaylistDrawerOpen, setCreatePlaylistDrawerOpen] = useState(false);

    const handleTimeRangeChange = (newRange) => {
        setTimeRange(newRange);
    };

    const handleNumberOfEntriesChange = (e) => {
        setNumberOfEntries(Number(e.target.value))
    };

    const createNewPlaylist = (title, description, selectedTracks) => {
        spotifyApi.createPlaylist(title, { description: description }).then(res => {
            spotifyApi.addTracksToPlaylist(res.body.id, getSelectionURIs(selectedTracks))
            .then(setCreatePlaylistDrawerOpen(false))
        });
    }

    const handleDrawerClose = () => {
        setCreatePlaylistDrawerOpen(false);
    }

    const handleMakePlaylistClick = () => {
        setCreatePlaylistDrawerOpen(true);
    };

    const getSelectionURIs = (selectedTracks) => {
        return selectedTracks.map((track) => {
            return track.uri
        })
    };

    const getTimeRange = (value) => {
        if (value === 0) {
            return 'short_term'
        } else if(value === 2) {
            return 'long_term'
        } else {
            return 'medium_term'
        }
    };

    useEffect(() => {
        if (!auth.accessToken) return
        spotifyApi.setAccessToken(auth.accessToken);

        spotifyApi.getMyTopArtists({limit: numberOfEntries, time_range: getTimeRange(timeRange)}).then(res => {
            setTopArtists(
                res.body.items.map(artist => {
                const largestAlbumImage = artist.images.reduce(
                    (largest, image) => {
                    if (image.height > largest.height) return image
                    return largest
                    },
                    artist.images[0]
                )

                const smallestAlbumImage = artist.images.reduce(
                    (smallest, image) => {
                    if (image.height < smallest.height) return image
                    return smallest
                    },
                    artist.images[0]
                )
    
                return {
                    title: artist.name,
                    uri: artist.uri,
                    image: largestAlbumImage.url,
                    icon: smallestAlbumImage.url,
                    id: artist.id
                }
                })
            )
        });
    }, [auth.accessToken, timeRange, numberOfEntries]);

    useEffect(() => {
        const getTopTracksForTopArtists = () => {
            return topArtists.map((artist) => {
                return spotifyApi.getArtistTopTracks(artist.id, 'GB')
                    .then((res) => {
                        if(res.body.tracks.length > 5) {
                            return res.body.tracks.slice(0, 5)
                        }
                        return res.body.tracks;
                    })
                    .then((res) => {
                        return res.map((track) => {
                            const largestAlbumImage = track.album.images.reduce(
                                (largest, image) => {
                                if (image.height > largest.height) return image
                                return largest
                                },
                                track.album.images[0]
                            )
            
                            const smallestAlbumImage = track.album.images.reduce(
                                (smallest, image) => {
                                if (image.height < smallest.height) return image
                                return smallest
                                },
                                track.album.images[0]
                            )
                
                            return {
                                title: track.name,
                                subtitle: track.artists[0].name,
                                uri: track.uri,
                                image: largestAlbumImage.url,
                                icon: smallestAlbumImage.url
                            }
                        })
                    })
            })
        }

        Promise.all(getTopTracksForTopArtists())
            .then((values) => {
                setTopTracksForArtists(values.flat())
            });
    }, [topArtists]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ConfigurationPanel
                        timeRange={timeRange} 
                        onTimeRangeChange={handleTimeRangeChange}
                        numberOfEntries={numberOfEntries}
                        onNumberOfEntriesChange={handleNumberOfEntriesChange}
                        onMakePlaylistClick={handleMakePlaylistClick}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TopTracksMediaContainer
                        timeRange={timeRange}
                        numberOfEntries={numberOfEntries}
                        topTracks={topArtists}
                    />
                </Grid>
            </Grid>
            <CreatePlaylistDrawer 
                tracks={topTracksForArtists} 
                open={createPlaylistDrawerOpen} 
                onClose={handleDrawerClose} 
                onSubmitPlaylist={createNewPlaylist}
            />
        </>
    )
}
