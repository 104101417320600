import { Box, Button, Card, CardContent, Grid, Slider, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import TimeRangeSelector from './TimeRangeSelector';

const MakePlaylistButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    }
}))

export default function ConfigurationPanel(props) {
    const {
        timeRange,
        onTimeRangeChange,
        numberOfEntries,
        onNumberOfEntriesChange,
        onMakePlaylistClick
    } = props;

    return (
        <Card>
            <CardContent sx={{padding: 3, paddingRight: 4, paddingLeft: 4}}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='subtitle1' sx={{marginBottom: 1}}>Time range</Typography>
                        <TimeRangeSelector 
                            value={timeRange}
                            onChange={onTimeRangeChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Typography variant='subtitle1' sx={{marginBottom: 1}}>Number of entries</Typography>
                            <Typography variant='h6' sx={{marginBottom: 1}}>{numberOfEntries}</Typography>
                        </Box>
                        <Slider 
                            value={typeof numberOfEntries === 'number' ? numberOfEntries : 0}
                            onChange={onNumberOfEntriesChange}
                            step={1}
                            min={1}
                            max={50}
                        />
                    </Grid>
                    <Grid item xs={12} align={'right'}>
                        <MakePlaylistButton 
                            color='primary' 
                            variant='contained'
                            onClick={onMakePlaylistClick}
                        >
                            Make it a playlist
                        </MakePlaylistButton>
                    </Grid>
                </Grid>   
            </CardContent>
        </Card>
    )
}
