import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { CookieConsent } from 'react-cookie-consent';

export const AuthContext = createContext(); 

export default function AuthProvider(props) {
    const {
        code,
        children
    } = props;

    const [cookies, setCookie, removeCookie] = useCookies(['accessToken', 'refreshToken', 'expiresIn']);

    const getCookieExpiry = (expiry) => {
        let now = new Date().getTime();
        return new Date(now + (expiry * 1000));
    }

    useEffect(() => {
        if (cookies.accessToken || !code) return

        axios.post("https://api.mylisteninghabits.com/login", {
            code
        })
        .then(res => {
            setCookie('accessToken', res.data.accessToken, { expires: getCookieExpiry(res.data.expiresIn), path: '/' });
            setCookie('refreshToken', res.data.refreshToken, { path: '/' });
            setCookie('expiresIn', res.data.expiresIn, { path: '/' });
            window.history.pushState({}, null, "/");
        })
        .catch(() => {
            // window.location = "/";
        })
    }, [code]);

    useEffect(() => {
        if (!cookies.refreshToken || !cookies.expiresIn) return
        const interval = setInterval(() => {
            axios
            .post("https://api.mylisteninghabits.com/refresh", {
                refreshToken: cookies.refreshToken,
            })
            .then(res => {
                setCookie('accessToken', res.data.accessToken, { expires: getCookieExpiry(30), path: '/' });
                setCookie('expiresIn', res.data.expiresIn);
            })
            .catch((err) => {
                // window.location = "/"
            })
        }, (cookies.expiresIn - 60) * 1000)

        return () => clearInterval(interval)
    }, [cookies.refreshToken, cookies.expiresIn]);

    return <AuthContext.Provider value={{ code, accessToken: cookies.accessToken }}>
        {children}
        <CookieConsent
            location="bottom"
            buttonText="I understand"
        >
            This site uses cookies for functionality.{" "}
            <span style={{ fontSize: "10px" }}>No personal data is stored</span>
        </CookieConsent>
    </AuthContext.Provider>
}