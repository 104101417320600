import React, { useState, useEffect } from "react";
import useAuth from "./useAuth";
import SpotifyWebApi from "spotify-web-api-node"
import { AppBar, Container, Grid, List, Toolbar, Typography } from "@mui/material";
import TabBar from "./TabBar";
import TopTracks from "./TopTracks";
import TopArtists from "./TopArtists";
import { useCookies } from "react-cookie";

const spotifyApi = new SpotifyWebApi({
    clientId: "f999f88ebf894ec2b014e9ea144e4c48",
})

export default function Dashboard(props) {
    const [tab, setTab] = React.useState(0);
    
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        My Listening Habits
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container>
                <TabBar value={tab} onChange={handleTabChange}/>
                {tab === 0 && <TopTracks />}
                {tab === 1 && <TopArtists />}
            </Container>
        </>
    )
}