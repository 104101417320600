import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import SpotifyWebApi from "spotify-web-api-node";
import TopTracksMediaContainer from './TopTracksMediaContainer';
import ConfigurationPanel from './components/ConfigurationPanel';
import CreatePlaylistDrawer from './components/CreatePlaylistDrawer';
import useAuth from './useAuth';

const spotifyApi = new SpotifyWebApi({
    clientId: "f999f88ebf894ec2b014e9ea144e4c48",
});

export default function TopTracks() {
    const auth = useAuth();

    const [topTracks, setTopTracks] = useState([]);
    const [createPlaylistDrawerOpen, setCreatePlaylistDrawerOpen] = useState(false);
    const [timeRange, setTimeRange] = useState(1);
    const [numberOfEntries, setNumberOfEntries] = useState(50);

    const handleTimeRangeChange = (newRange) => {
        setTimeRange(newRange);
    };

    const handleNumberOfEntriesChange = (e) => {
        setNumberOfEntries(Number(e.target.value))
    }

    const getTimeRange = (value) => {
        if (value === 0) {
            return 'short_term'
        } else if(value === 2) {
            return 'long_term'
        } else {
            return 'medium_term'
        }
    };

    const createNewPlaylist = (title, description, selectedTracks) => {
        spotifyApi.createPlaylist(title, { description: description }).then(res => {
            spotifyApi.addTracksToPlaylist(res.body.id, getSelectionURIs(selectedTracks))
            .then(setCreatePlaylistDrawerOpen(false))
        });
    }

    const handleDrawerClose = () => {
        setCreatePlaylistDrawerOpen(false);
    }

    const handleMakePlaylistClick = () => {
        setCreatePlaylistDrawerOpen(true);
    };

    const getSelectionURIs = (selectedTracks) => {
        return selectedTracks.map((track) => {
            return track.uri
        })
    };

    useEffect(() => {
        if (!auth.accessToken) return
        spotifyApi.setAccessToken(auth.accessToken);

        spotifyApi.getMyTopTracks({limit: numberOfEntries, time_range: getTimeRange(timeRange)}).then(res => {
            setTopTracks(
                res.body.items.map(track => {
                const largestAlbumImage = track.album.images.reduce(
                    (largest, image) => {
                    if (image.height > largest.height) return image
                    return largest
                    },
                    track.album.images[0]
                )

                const smallestAlbumImage = track.album.images.reduce(
                    (smallest, image) => {
                    if (image.height < smallest.height) return image
                    return smallest
                    },
                    track.album.images[0]
                )
    
                return {
                    title: track.name,
                    subtitle: track.artists[0].name,
                    uri: track.uri,
                    image: largestAlbumImage.url,
                    icon: smallestAlbumImage.url
                }
                })
            )
        });
    }, [auth.accessToken, timeRange, numberOfEntries]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ConfigurationPanel 
                        timeRange={timeRange} 
                        onTimeRangeChange={handleTimeRangeChange}
                        numberOfEntries={numberOfEntries}
                        onNumberOfEntriesChange={handleNumberOfEntriesChange}
                        onMakePlaylistClick={handleMakePlaylistClick}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TopTracksMediaContainer
                        timeRange={timeRange}
                        numberOfEntries={numberOfEntries}
                        topTracks={topTracks}
                    />
                </Grid>
            </Grid>
            <CreatePlaylistDrawer 
                tracks={topTracks} 
                open={createPlaylistDrawerOpen} 
                onClose={handleDrawerClose} 
                onSubmitPlaylist={createNewPlaylist}
            />
        </>
    )
}
