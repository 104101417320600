import { Container, Tab, Tabs, styled } from '@mui/material'
import React from 'react';
import TopArtists from './TopArtists';
import TopTracks from './TopTracks';

const StyledTab = styled(Tab)(({theme}) => ({
    color: theme.palette.text.primary,
}))

export default function TabBar(props) {
    const {
        value,
        onChange
    } = props;

    return (
        <Tabs value={value} onChange={onChange} variant="fullWidth" sx={{marginBottom: 2}}>
            <StyledTab label="Top Tracks"/>
            <StyledTab label="Top Artists"/>
        </Tabs>
    )
}
