import Login from './Login';
import Dashboard from './Dashboard';
import {
  Routes,
  Route
} from "react-router-dom";
import AuthProvider from './AuthProvider';
import RequireAuth from './RequireAuth';

const code = new URLSearchParams(window.location.search).get("code");

function App() {
  return (
    <AuthProvider code={code}>
      <Routes>
        <Route 
          path="/" 
          element={
            <RequireAuth>
              <Dashboard code={code}/>
            </RequireAuth>
          } 
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </AuthProvider>
  )
}

export default App
