import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from './useAuth';

export default function RequireAuth(props) {
    let auth = useAuth();
    let location = useLocation();
  
    if (!auth.accessToken) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return props.children
}